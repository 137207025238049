import * as React from "react";
import styled from "styled-components";
import { Container, Section, Header, Content } from "./primatives";
import Logo from "../../images/logo.svg";

const Navbar = styled.div`
    display: flex;
    align-items: center;
    width: 500px;
    justify-content: space-between;
`;

const NavLink = styled.a`
    text-decoration: none;
    color: white;
    margin: 0;
    padding: 0;
    height: auto;
    :hover {
        color: lightblue;
    }
`;

const Slogan = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 2rem;
    color: white;
    justify-content: center;
    align-items: center;
    height: 100%;
`;
function Wip() {
    return (
        <Section>
            <Container primary>
                <div style={{ height: "10%", marginLeft: "1em" }}>
                    <Navbar>
                        <Header>Disco Studios</Header>
                    </Navbar>
                </div>
                <div
                    style={{
                        display: "flex",
                        height: "80%",
                        justifyContent: "center",
                        margin: "1em",
                    }}
                >
                    <Slogan>
                        <>CODING IS A DELICATE DANCE,</>
                        <div style={{ fontWeight: 500, fontStyle: "italic" }}>
                            AND WE KNOW HOW TO BOOGIE.
                        </div>
                        <div style={{ fontSize: "0.8rem" }}>
                            Cloud Development - Mobile Development - Web
                            Development
                        </div>
                    </Slogan>
                    <Logo style={{ width: "30%" }}></Logo>
                </div>
                <div
                    style={{
                        backgroundColor: "#121326",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Header>This website is a work in progress.</Header>
                </div>
                <div
                    style={{
                        backgroundColor: "#121326",
                        height: "5%",
                        paddingBottom: "1em",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center"
                    }}
                >
                    <Content>We are grateful to be busy servicing our clients!</Content>
                    <Content>For more information about us, feel free to reach out at contact@discostudios.io.</Content>
                </div>
            </Container>
        </Section>
    );
}

export default Wip;
