import styled from "styled-components";

export interface LayoutProps {
    primary?: boolean;
    padding?: boolean;
}

export interface TextProps {
    dark?: boolean;
}

// ---- LAYOUT COMPONENTS ---- //

export const Container = styled.div`
    height: 100%;
    width: auto;
    display: flex;
    background-color: ${(props: LayoutProps) =>
        props.primary ? "#2e3192" : "white"};
    flex-direction: column;
    padding: ${(props: LayoutProps) => (props.padding ? "0 1em 0 1em" : "0")};
`;

export const Section = styled.div`
    height: 100vh;
`;

// ---- TEXT COMPONENTS ---- //

export const Content = styled.div`
    color: ${(props: TextProps) => (props.dark ? "black" : "white")};
`;

export const Header = styled.h1`
    color: ${(props: TextProps) => (props.dark ? "black" : "white")};
    font-weight: 500;
`;
