import * as React from "react";
import styled from "styled-components";
import About from "../components/about";
import Welcome from "../components/welcome";
import Wip from "../components/wip";
// import About from "../components/about";

const Main = styled.div`
    height: 100%;
    margin: 0;
    padding: 0;
`;

const IndexPage = () => {
    return (
        <Main>
            <title>Disco</title>
            {/* <Welcome></Welcome>
            <About></About> */}
            <Wip></Wip>
        </Main>
    );
};

export default IndexPage;
